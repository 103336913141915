import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllLicensesByResellerId, insertLicense, updateLicense } from "../../../actions/licensesActions";
import "./Licenses.scss";

function Licenses() {
    const [licenses, setLicenses] = useState([]);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [newLicense, setNewLicense] = useState({
        StoreName: "",
        Owner: "",
        ValidityDate: new Date().toISOString().split("T")[0],
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const identity = useSelector((state) => state.identity);

    useEffect(() => {
        loadLicenses();
    }, []);

    const loadLicenses = () => {
        dispatch(getAllLicensesByResellerId(identity.user.resellerId)).then((json) => {
            // const treeData = createTree(json);
            setLicenses(json);
        });
    };

    const handleUpdate = (updatedRow) => {
        dispatch(updateLicense(updatedRow)).then(() => loadLicenses());
    };

    const handleInsert = () => {
        const dataObject = {
            ...newLicense,
            resellerId: identity.user.resellerId,
        };
        dispatch(insertLicense(dataObject)).then(() => {
            loadLicenses();
            setIsAddDialogOpen(false);
            setNewLicense({
                StoreName: "",
                Owner: "",
                ValidityDate: new Date().toISOString().split("T")[0],
            });
        });
    };

    const columns = [
        {
            field: "StoreName",
            headerName: "Name",
            flex: 1,
            editable: true,
            renderCell: (params) => <span style={{ paddingLeft: params.row.level * 20 }}>{params.value}</span>,
        },
        { field: "Owner", headerName: "Inhaber / GF", flex: 1, editable: true },
        {
            field: "ValidityDate",
            headerName: "Lizenzablauf",
            flex: 1,
            editable: true,
            type: "date",
            valueGetter: (value) => new Date(value),
        },
        { field: "LicenseNumber", headerName: "Lizenznummer", flex: 1 },
        { field: "deployFrontendUrl", headerName: "Frontend URL", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: "Aktionen",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => navigate(`/lizenzen/${id}`)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const createTree = (licenses, parentId = null, level = 0, parentPath = []) => {
        return licenses
            .filter((license) => license.parentLicenseId === parentId)
            .map((license) => {
                const path = [...parentPath, license.LicenseId];
                return {
                    ...license,
                    level,
                    path,
                    children: createTree(licenses, license.LicenseId, level + 1, path),
                };
            });
    };

    return (
        <div className="licenses">
            <Typography variant="h4" gutterBottom sx={{ padding: 2, mb: 2 }}>
                Lizenzen
            </Typography>
            <Box sx={{ width: "100%" }}>
                <DataGrid
                    rows={licenses}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20, 50, 100]}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.LicenseId}
                    onCellEditCommit={handleUpdate}
                    treeData
                    getTreeDataPath={(row) => row.path}
                />
            </Box>{" "}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => setIsAddDialogOpen(true)} sx={{ mt: 2 }}>
                    Lizenz hinzufügen
                </Button>
            </Box>
            <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
                <DialogTitle>Neue Lizenz hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        variant="standard"
                        value={newLicense.StoreName}
                        onChange={(e) => setNewLicense({ ...newLicense, StoreName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Inhaber / GF"
                        fullWidth
                        variant="standard"
                        value={newLicense.Owner}
                        onChange={(e) => setNewLicense({ ...newLicense, Owner: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Lizenzablauf"
                        type="date"
                        fullWidth
                        variant="standard"
                        value={newLicense.ValidityDate}
                        onChange={(e) => setNewLicense({ ...newLicense, ValidityDate: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddDialogOpen(false)}>Abbrechen</Button>
                    <Button onClick={handleInsert}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Licenses;
